<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="card-title px-4 mt-1">
                        Filters
                        <v-btn class="my-2 mx-2" dark small color="#027a00" @click="createCard">
                            <v-icon dark>mdi-plus</v-icon>Create
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <filters-alternative
                            :filters="filterFields"
                            :sort-options="headers.filter(element => element.sortable)"
                            @reloadFilters="reloadFilters"
                            @filterResults="filterResults"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-data-table
            item-key="id"
            :headers="headers"
            :items="cards"
            :items-per-page="itemsPerPage"
            :loading="loading"
            hide-default-footer
            class="elevation-1"
        >
            <template slot="item" slot-scope="props">
                <tr>
                    <td>{{ props.item.number }}</td>
                    <td>{{ formatDate(props.item.expirationDate, 'yyyy-MM-DD') }}</td>
                    <td>{{ props.item.active }}</td>
                    <td>{{ props.item.member.firstName }}</td>
                    <td>{{ props.item.member.lastName }}</td>
                    <td>{{ props.item.type }}</td>
                    <td>{{ props.item.qr }}</td>
                    <td>
                        <div class="buttons">
                            <v-btn class="mx-2" dark small color="#004cff" @click="handleClick('edit', props.item)">
                                <v-icon dark>mdi-account-edit</v-icon>
                            </v-btn>
                            <delete-button @delete-entry="onDelete(props.item)" />
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="7"
        />
    </v-container>
</template>

<script>
    import moment from 'moment'
    import cardService from '@/services/card-service'
    import DeleteButton from '@/components/DeleteButton'
    import FiltersAlternative from '@/components/FiltersAlternative'

    export default {
        name: 'CardRoute',
        components: { DeleteButton, FiltersAlternative },
        data: () => {
            return {
                panel: null,
                cards: [],
                filterFields: [
                    {
                        text: 'Member',
                        value: 'member',
                        type: 'text',
                        searchable: true,
                        data: null
                    },
                    {
                        text: 'Card',
                        value: 'number',
                        type: 'text',
                        searchable: true,
                        data: null
                    }
                ],
                headers: [
                    {
                        text: 'Card Number',
                        value: 'number',
                        align: 'left'
                    },
                    { text: 'Expiration Date', value: 'expirationDate' },
                    { text: 'Active', value: 'active' },
                    { text: 'Member First Name', value: 'firstName' },
                    { text: 'Member Last Name', value: 'lastName' },
                    { text: 'Type', value: 'type' },
                    { text: 'Qr', value: 'qr' }
                ],
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                loading: false,
                sortOption: null,
                order: null,
                filters: null
            }
        },
        watch: {
            page() {
                var path = `/cards?page=${this.page}`
                if (this.$route.fullPath !== path) {
                    this.$router.push(path)
                }
                this.fetchFilteredCards()
            },
            $route(to, from) {
                if (to.name !== from.name) {
                    this.page = this.$route.query.page ? parseInt(this.$route.query.page) : this.page
                    this.fetchFilteredCards()
                }
            }
        },
        created() {
            this.page = this.$route.query.page ? parseInt(this.$route.query.page) : this.page
            this.filters = this.$store.getters.getFilters
            this.sortOption = this.$store.getters.getSortOption
            this.order = this.$store.getters.getSortOrder
        },
        methods: {
            reloadFilters() {
                this.order = 'ASC'
                this.sortOption = null
                this.filters = {}
                this.fetchFilteredCards()
            },
            handleClick(mode, item) {
                this.$router.push(`/cards/${item.id}/${mode}?page=${this.page}`)
            },
            onDelete(item) {
                cardService.deleteCardById(item.id).then(() => {
                    this.fetchFilteredCards()
                })
            },
            createCard() {
                this.$router.push(`/cards/create?page=${this.page}`)
            },
            fetchFilteredCards() {
                this.loading = true
                cardService.getCardsByFilter(this.page, this.filters, this.sortOption, this.order).then(resp => {
                    this.cards = resp.data.data
                    this.pageCount = resp.data.last_page
                    this.loading = false
                })
            },
            formatDate(date, format) {
                return moment(date).format(format)
            },
            filterResults(filterObject, sortOption = null, order = 'ASC', page = false) {
                this.sortOption = sortOption
                this.order = order
                this.filters = filterObject
                this.loading = true
                if (page) {
                    this.page = 1
                }
                cardService.getCardsByFilter(this.page, this.filters, this.sortOption, this.order).then(resp => {
                    if (this.page > this.pageCount) {
                        this.page = 1
                    }
                    this.pageCount = resp.data.last_page
                    this.cards = resp.data.data
                    this.loading = false
                })
            }
        }
    }
</script>

<style scoped>
.search {
    max-width: 300px;
}
.table-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
td {
    min-width: 150px;
}
.buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}
</style>
